import { DEV_CONSTANTS } from '@/dev'

/**
 * ファイルパスにベースパスを付与
 * @desc dev環境ではviteに設定したパブリックベースパスが無視されるため、実行時にパスの修正が必要
 * @param path ファイルパス
 * @returns パス
 */
export const addPublicBasePath = (path: string): string => {
  if (!import.meta.env.DEV) return path
  return `http://localhost:${DEV_CONSTANTS.PORT}${path}`
}
