import { photographerConstants } from '@/utils/constants/photographerConstants'
import notoSansJpThin from '@/fonts/NotoSans/NotoSansJP-Thin.otf'
import notoSansJpLight from '@/fonts/NotoSans/NotoSansJP-Light.otf'
import notoSansJpRegular from '@/fonts/NotoSans/NotoSansJP-Regular.otf'
import notoSansJpMedium from '@/fonts/NotoSans/NotoSansJP-Medium.otf'
import notoSansJpBold from '@/fonts/NotoSans/NotoSansJP-Bold.otf'
import notoSansJpBlack from '@/fonts/NotoSans/NotoSansJP-Black.otf'
import favicon from '@/media/img/favicon.ico'
import { addPublicBasePath } from '@/utils/functions/vite'

const {
  FONT_FAMILY: { NOTO_SANS_JP },
} = photographerConstants

/**
 * FontFaceオブジェクトの生成
 * @param path フォントファイルパス
 * @param weight フォントウェイト
 * @returns FontFaceオブジェクト
 */
const createFontFace = (path: string, weight: string): FontFace =>
  new FontFace(NOTO_SANS_JP, `url(${addPublicBasePath(path)})`, {
    weight,
  })

/**
 * フォントファイルの読み込みとフォントファミリーの設定
 */
export const loadFontFamily = async (): Promise<void> => {
  const fontFaceParams: Parameters<typeof createFontFace>[] = [
    [notoSansJpThin, '100'],
    [notoSansJpLight, '300'],
    [notoSansJpRegular, '400'],
    [notoSansJpMedium, '500'],
    [notoSansJpBold, '700'],
    [notoSansJpBlack, '900'],
  ]
  const loadedFaces = await Promise.all(
    fontFaceParams
      .map(([path, weight]) => createFontFace(path, weight))
      .map((fontFace) => fontFace.load())
  )
  loadedFaces.forEach((loadedFace) =>
    (
      document.fonts as unknown as { add: (loadedFace: FontFace) => void }
    ).add?.(loadedFace)
  )
  document.body.style.fontFamily = `"${NOTO_SANS_JP}", sans-serif`
}

/**
 * ファビコン用要素の取得
 * @returns linkタグ情報
 */
const getFaviconLink = (): HTMLLinkElement => {
  const existingIconLink =
    document.head.querySelector<HTMLLinkElement>('link[rel="icon"]')
  if (existingIconLink) return existingIconLink
  const newIconLink = document.createElement('link')
  newIconLink.rel = 'icon'
  document.head.appendChild(newIconLink)
  return newIconLink
}

/**
 * ファビコンの設定
 */
export const setFavicon = (): void => {
  const link = getFaviconLink()
  link.href = addPublicBasePath(favicon)
}
